<template>
	<v-row justify="center" v-if="dataLoaded">
		<v-dialog :value="dialog" max-width="850" @input="closeDialog">
			<v-card class="dialog-card" :loading="cardLoading">
				<v-card-title class="headline accent--text pb-4">
          Результаты голосования членов аттестационной комиссии
        </v-card-title>

				<v-card-text outlined tile class="border-top px-2">
          <v-card class="mt-4 elevation-0">
            <v-card-title class="pt-1 pb-0 align-start">
              <span
                :title="!isEditEnable && 'Цикл завершён'"
                :class="{ 'cursor-help': !isEditEnable }"
              >
                <v-btn
                  :loading="setVoteForAllLoading"
                  :disabled="!isEditEnable"
                  small
                  color="primary"
                  class="white--text px-4 mb-0"
                  @click="setVoteForAll"
                >
                  <v-icon left size="20">mdi-check</v-icon>За по умолчанию
                </v-btn>
              </span>

              <v-spacer></v-spacer>

              <v-text-field
                v-model="tableSearch"
                class="mt-0 pt-0 table-search"
                append-icon="mdi-magnify"
                label="Поиск по ФИО"
                single-line
                clearable
              />
            </v-card-title>

            <v-data-table
              class="base-table"
              fixed-header
              hide-default-footer
              :headers="headers"
              :items="listeners"
              :search="tableSearch"
              :items-per-page="-1"
            >
              <template #item.votesCount="{ item }" v-if="isEditEnable">
                <div class="d-flex justify-center">
                  <v-text-field
                    v-model="item.votesCount"
                    class="vote-number mt-0 pt-0"
                    type="number"
                    min="0"
                    :max="countMembers"
                    hide-details
                    @input="changeVote(item, $event)"
                  />
                </div>
              </template>

              <template #item.against="{ item }">
                <span>{{ item.hasVote ? countMembers - item.votesCount : 0 }}</span>
              </template>

              <template slot="no-data">
                <div class="py-3">Нет слушателей на данном цикле</div>
              </template>

              <template slot="no-results">
                <div class="py-3">Ничего не найдено</div>
              </template>
            </v-data-table>
          </v-card>
				</v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { examApi, fileDpoCardApi, gradesApi } from '@/api'

export default {
  name: 'VoteMembersDialog',

	props: {
		dialog: Boolean
	},

	data: () => ({
    dataLoaded: false,
    cardLoading: false,
    setVoteForAllLoading: false,
    tableSearch: null,
    headers: [
      { text: 'ФИО', value: 'fileDpoCard.fullName', sortable: false, align: 'left' },
      { text: 'За', value: 'votesCount', sortable: false, align: 'center' },
      { text: 'Против', value: 'against', sortable: false, align: 'center' },
    ],
    countMembers: 0,
    countVotes: 0,
    listeners: []
	}),

	computed: {
    ...mapGetters('user', ['isAdmin', 'isSuperAdmin']),
    ...mapGetters('cycle', ['cycleId', 'isCycleComplete']),

    isEditEnable() {
      return !this.isCycleComplete
        || (this.isCycleComplete && this.countVotes === 0)
        || (this.isAdmin || this.isSuperAdmin)
    }
	},

	methods: {
    async getCountMembers() {
      try {
        this.countMembers = await examApi.getCountMembers(this.cycleId)

        if (this.countMembers === 0) {
          this.$emit('showWarning', 'Члены комиссии ещё не назначены')
        } else {
          await this.getAccountingListeners()
          await this.getVotes()
        }
      } catch (e) {
        console.log(e.status)
      }
    },

    async getAccountingListeners() {
      try {
        this.listeners = await fileDpoCardApi.getAccountingListeners(this.cycleId)
      } catch (e) {
        console.log(e.status)
      }
    },

    async getVotes() {
      try {
        const votes = await gradesApi.getVotes(this.cycleId)
        this.countVotes = votes.length

        this.listeners = this.listeners.map(listener => {
          return {
            fileDpoCard: listener,
            ...this.findVotesCount(votes, listener)
          }
        })

        this.$emit('dataLoaded')
        this.dataLoaded = true
      } catch (e) {
        console.log(e.status)
      }
    },

    findVotesCount(votesArray, listener) {
      const vote = votesArray.find(el => el.fileDpoCard.fileDpoCardId === listener.fileDpoCardId)

      return {
        votesCount: vote?.votesCount || 0,
        hasVote: vote?.votesCount !== undefined
      }
    },

    async changeVote(item, value) {
      if (value) {
        await this.$nextTick()

        if (!/^(([1-9]\d*)|0)?$/.test(value)) item.votesCount = 0
        if (+value > this.countMembers) item.votesCount = this.countMembers
        if (+value < 0) item.votesCount = 0
        item.hasVote = true

        const body = {
          fileDpoCard: item.fileDpoCard,
          votesCount: item.votesCount
        }

        try {
          this.cardLoading = true
          await gradesApi.setVote(body)
        } catch (e) {
          this.showErrorMessage(e, 'Ошибка сохранения голоса')
        } finally {
          this.cardLoading = false
        }
      }
    },

    async setVoteForAll() {
      try {
        this.setVoteForAllLoading = true

        await gradesApi.setVoteForAll(this.cycleId)
        this.listeners.forEach(el => {
          el.votesCount = this.countMembers
          el.hasVote = true
        })
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения голосов')
      } finally {
        this.setVoteForAllLoading = false
      }
    },

    resetModal() {
      this.tableSearch = null
      this.countMembers = 0
      this.listeners = []
    },

    closeDialog() {
      this.resetModal()
      this.dataLoaded = false
			this.$emit('closeDialog')
		}
	},

  watch: {
    dialog(val) {
      val ? this.getCountMembers() : this.resetModal()
    }
  }
}
</script>

<style scoped>
.base-table {
  overflow-y: auto;
  min-height: 377px;
  max-height: 377px;
  border-radius: 0 !important;
}

.base-table >>> .vote-number {
  max-width: 40px;
  position: relative;
  left: 14px;
}

.base-table >>> .vote-number .v-input__slot:before {
  border-top: none;
}

.base-table >>> input[type=number]::-webkit-inner-spin-button,
.base-table >>> input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
</style>