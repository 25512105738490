<template>
  <div class="text-center mt-2" v-if="!dataLoaded">
    <v-progress-circular :size="50" color="primary" indeterminate />
  </div>

  <div v-else>
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <v-card class="base-card pa-5">
          <v-row class="my-0" v-if="!errorMessage">
            <v-col cols="12" class="py-0">
              <div class="d-flex justify-space-between">
                <ul class="custom-tabs">
                  <li :class="{'active': $route.name === 'accountingByListeners'}" @click="$router.push('by-listeners')">
                    По слушателям
                  </li>
                  <li></li>
                  <li :class="{'active': $route.name === 'accountingByThemes'}" @click="$router.push('by-themes')">
                    По разделам
                  </li>
                </ul>

                <v-btn
                  :loading="loading"
                  small
                  color="primary"
                  class="white--text px-4"
                  @click="openVoteMembersDialog"
                >
                  <v-icon left small>mdi-account-multiple-outline</v-icon>Голосование комиссии
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-alert type="error" outlined class="body-2 mb-0" v-else>
            {{ errorMessage }}
          </v-alert>
        </v-card>
      </v-col>
    </v-row>

    <VoteMembersDialog
      :dialog="dialog"
      @showWarning="showWarning"
      @dataLoaded="loading = false"
      @closeDialog="closeDialog"
    />

    <router-view
      v-if="!errorMessage"
      :infoBlockHeight="infoBlockHeight"
    />
  </div>
</template>

<script>
import { themeApi, scheduleApi, cycleApi } from '@/api'
import { mapGetters } from 'vuex'
import { Themes } from '@/models'
import VoteMembersDialog from '@/components/cycles/cycle/accounting/dialogs/VoteMembers'

export default {
  name: 'Accounting',

  metaInfo: {
    title: 'Учет успеваемости'
  },

  components: {
    VoteMembersDialog
  },

  props: {
    infoBlockHeight: Number
  },

  async created() {
    await this.getThemes()
    this.dataLoaded = true
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  data: () => ({
    dataLoaded: false,
    dialog: false,
    loading: false,
    errorMessage: null
  }),

  computed: {
    ...mapGetters('cycle', ['cycleId', 'isCycleComplete'])
  },

  methods: {
    async getThemes() {
      try {
        const themes = await themeApi.getThemes(this.cycleId)
        //!themes.length && await this.getChapters()
      } catch (e) {
        console.log(e.status)
      }
    },

/*    async getChapters() {
      try {
        const chapters = await scheduleApi.getChapters(this.cycleId)
        chapters.length && await this.sendChaptersAsThemes(chapters)
      } catch (e) {
        this.errorMessage = ![500].includes(e?.status) ? e.data?.message : 'Ошибка получения оценок'
      }
    },

    async sendChaptersAsThemes(chapters) {
      try {
        const themes = new Themes()

        chapters.forEach(chapter => {
          const themeFromChapter = {
            ...chapter,
            name: chapter.chapter,
            cycleId: this.cycleId
          }

          themes.addTheme(themeFromChapter)
        })

        await cycleApi.addThemeList(themes.getForAPI())
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка создания разделов')
      }
    },*/

    openVoteMembersDialog() {
      this.loading = true
      this.dialog = true
    },

    showWarning(text) {
      this.loading = false
      this.dialog = false
      this.$toastr('warning', '', text)
    },

    closeDialog() {
      this.dialog = false
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>

<style scoped>
.custom-tabs {
  padding-left: 0;
}

.custom-tabs li {
  list-style-type: none;
  display: inline;
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  color: var(--accent-color);
}

.custom-tabs li:nth-child(2) {
  margin-left: 8px;
  margin-right: 8px;
  border-right: 1px solid grey;
  cursor: default;
}

.custom-tabs li.active {
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 5px;
}
</style>